import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrl: './input-file.component.scss'
})
export class InputFileComponent extends FieldType {
  imageSrc: string | undefined;
  files: any[] = [];
  valueToDisplayInInput: string = ''

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.field.formControl?.value.length > 0) {
      this.valueToDisplayInInput = this.field.formControl?.value;
    }
  }

  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }


  onFileSelected(event: Event) {

    // If Single File Select -> Reset Input
    if (this.props['multiple'] == false) {
      this.clearInput();
    }

    const files: any = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(files[i]));
        // Show first img as preview in input
        if (i == 0 && !this.imageSrc) {
          this.imageSrc = window.URL.createObjectURL(files[i]);
        }
        files[i]['url'] = url
        this.files.push(files[i]);
      }
    }

    this.field.formControl?.setValue(this.files); // Mettez à jour le tableau d'images dans le FormControl
    this.valueToDisplayInInput = this.field.formControl?.value.map((file: any) => file.name).join(','); // Update display input

  }

  clearInput() {
    this.files = [];
    this.valueToDisplayInInput = "";
    this.imageSrc = undefined;
    this.field.formControl?.reset();
  }

  removeFile(file: File) {
    const indexFile = this.files.findIndex((item: File) => item == file);
    if (indexFile != -1) {
      this.files.splice(indexFile, 1);
      this.field.formControl?.setValue(this.files); // Mettez à jour le tableau d'images dans le FormControl
      this.valueToDisplayInInput = this.field.formControl?.value.map((file: any) => file.name).join(','); // Update display input
    }
  }


}
