import { Component, Input } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-serious-game-card',
  templateUrl: './serious-game-card.component.html',
  styleUrl: './serious-game-card.component.scss',
})
export class SeriousGameCardComponent {
  @Input() card!: any;
  @Input() instanceId?: any;

  constructor(
    private router: Router
  ) {}

  onClick() {
    const currentUrl = this.router.url;

    const pattern = /^\/admin\/adventures/;
    if (pattern.test(currentUrl)) {
      if(this.instanceId) {
        this.router.navigate([`/admin/serious-game/${this.card.game.id}/instances/${this.instanceId}`]);
      } else {
        this.router.navigate([`/admin/serious-game/${this.card.id}`]);

      }

    } else {
      this.router.navigate([
        `/serious-game/${this.card.id}/instances/${this.instanceId}`,
      ]);
    }
  }
}
